import Page from "../uiComponents/Layouts/Page";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


export default function Four04Page(){
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(()=>{
            navigate("/")
        }, 5000)
    }, [navigate]);

    return <Page className={'flex'}>
        <span className={"center-of-div"}>
            <h1>Oops, seems this page may have moved 👋🏽</h1>
            <h2>I will redirect you back to the home page in 5 seconds</h2>
        </span>
    </Page>
}

