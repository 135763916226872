import {IconType} from "react-icons";
import React from "react";

interface props extends React.HTMLAttributes<HTMLButtonElement>{
    Icon: IconType;
}

export default function IconBtn({Icon, ...rest}:props){

    return <button {...rest} className={`w-[40px] h-[40px] bg-gray-200 hover:bg-blue-100 rounded-lg ${rest.className}`} >
        <Icon className={"m-auto fill-blue-500 "} />
    </button>
}

