import React from "react";
import {CgSpinner} from "react-icons/cg";


interface props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    spin?: boolean;

}

export default function Button({text, spin, ...rest}:props){

    return <button {...rest} className={`w-max min-h-[2.5em] drop-shadow-lg active:bg-blue-600 bg-blue-400 hover:bg-blue-300 text-white
     pl-3 pr-3 rounded-lg flex ${rest.className}`}>
        <p className={"m-auto"}>{text}</p>
        {spin && <CgSpinner className={"m-auto animate-spin text-2xl text-white "} />}
    </button>
}

